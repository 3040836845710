<template>
    <div>
      <vx-card actionable class="cardx" title="Queue Report">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
            <v-select v-if="getrole('cm4')" label="cities" :options="city_options" v-model="selected_city"
              style="width: 240px; z-index: 1000"></v-select>
            <v-select v-if="getrole('cm1') || getrole('ac1') || getrole('exed')" disabled label="cities" :options="city_options"
              v-model="selected_city" style="width: 240px; z-index: 1000"></v-select>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
            <v-select v-if="getrole('cm4')" label="City classification" :options="city_classification_list"
              v-model="selected_city_classification" style="width: 240px; z-index: 1000"></v-select>
            <v-select v-if="getrole('cm1') || getrole('ac1') || getrole('exed')" disabled label="City classification"
              :options="city_classification_list" v-model="selected_city_classification"
              style="width: 240px; z-index: 1000"></v-select>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-button color="dark" type="filled" @click="getSpocsWithCityClasification()">GO</vs-button>
          </vs-col>
        </vs-row>
        <!-- U Level Table -->
        <GmQueueReportTable v-if="level_value == 'U Levels'"></GmQueueReportTable>
      </vx-card>
    </div>
  </template>

  <script>
  import Datepicker from "vuejs-datepicker";
  import DateRangePicker from "vue2-daterange-picker";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import vSelect from "vue-select";
  import axios from "axios";
  import constants from "../../constants.json";
  import EventBus from "../components/eventbus";
  import GmQueueReportTable from "./GmQueueReportTable.vue";
  export default {
    data() {
      return {
        level_value: "M Levels",
        selected_spocs: [],
        selected_city_classification: "",
        city_classification_list: [],
        spoc_names: [],
        dateRange: new Date(),
        selected_city: "",
        loggedin: "",
        city_options: [
          "Ahmedabad",
          "Bangalore",
          "Chennai",
          "Delhi",
          "Ernakulam",
          "Hyderabad",
          "Kolkata",
          "Mumbai",
          "Pune",
        ],
        cities: [],
        levels: ["M Levels", "U Levels"],
        selected_level: "U Levels",
        tableRows: [],
        citySelection: "",
        admin_access_spocs : [
        "ghouse.ahmed@mileseducation.com",
        "athira.m@mileseducation.com",
        "gagan.jot@mileseducation.com",
        "krishna.kanth@mileseducation.com",
        "anjum.taj@mileseducation.com",
        "nidhin.ram@mileseducation.com",
        "reshma.johnson@mileseducation.com",
        "satram.chaitanya@mileseducation.com",
        "sharon.j@mileseducation.com",
        "simran.basha@mileseducation.com",
      ],
      logged_in_team: '',
      spoc_email_id: '',
      login_spoc_id: "",

      };
    },
    mounted() {
      // console.log("Hrrlo");
      this.login_spoc_id = localStorage.getItem("spoc_id");
      
      this.logged_in_team = localStorage.getItem('team');
      this.spoc_email_id = localStorage.getItem("email_id");
      if (this.admin_access_spocs.includes(this.spoc_email_id)) {
        this.logged_in_team = 'Admin';
      }
      let team = localStorage.getItem('team');
      if(team == 'GM' && this.logged_in_team != 'Admin'){
        this.getGmReportingSpocs()
      }else{
        this.getSpocs();
      }
      EventBus.$on("updateCoverage", () => {
        this.updateValues();
      });
    },
    watch: {
      selected_city_classification(val) {
        this.selected_city_classification = val;
      },
      selected_city(val) {
        console.log("selcted city watch", val);
        if(localStorage.getItem('team') != 'GM' || this.logged_in_team == 'Admin'){
                this.getCityClasificationCodes(val);
        }else{
          this.getGmReportingSpocs();
      }
      },
      dateRange() {
        // this.updateValues();
      },
    },
    methods: {
      getGmReportingSpocs() {
        let gm_id = localStorage.getItem("spoc_id");
        let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log("getGmReportingSpocs", response, this.selected_city);
            this.cities = [];
            this.city_classification_list = [];
            if (response.data.spocs.length != 0) {
              // this.spoc_names = response.data.spocs
              let active_spocs = []
              response.data.spocs.forEach(element => {
                if (element.active == true) {
                  active_spocs.push(element)
                }
              })
              this.spoc_names = active_spocs
              this.loggedin = localStorage.getItem('spoc_id');
              let duplicates = []
              response.data.spocs.forEach(element => {
                if (!duplicates.includes(element.city) && element.active == true) {
                  this.cities.push(element.city)
                  duplicates.push(element.city)
                }
                // if (!duplicates.includes(element.city_classification) && element.active == true && this.selected_city == element.city) {
                //   this.city_classification_list.push(element.city_classification)
                //   duplicates.push(element.city_classification)
                //   this.selected_city_classification = this.city_classification_list[0];
                // }
              })
              console.log("active cities", this.cities);

              this.extractSpocByCity(this.spoc_names);
              // return true
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      },
      getSpocsWithCityClasification() {
        // let city = this.new_selected_city;
        console.log(this.selected_city);
        console.log(this.selected_city_classification);
        if(localStorage.getItem('team') != 'GM' || this.logged_in_team == 'Admin'){

        let url = `${constants.SERVER_API}getSpocsWithCityClasification?city=${this.selected_city}&city_clasification=${this.selected_city_classification}`;
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            this.citySelection = this.selected_city;
            console.log("getSpocsWithCityClasification", response);
            this.selected_spocs = response.data.spocs;
            console.log("step1", this.selected_spocs);

            // this.getSpocIds(spoc_data);
            this.level_value = this.selected_level;
            this.updateValues();
          })
          .catch((error) => {
            this.handleError(error);
          });
        }else{
          this.citySelection = this.selected_city;
          console.log("spoc_names1", this.spoc_names);
          let city_wise_spocs = [];
          this.spoc_names.forEach(element => {
            if(element.city == this.selected_city && this.selected_city_classification == "All"){
              city_wise_spocs.push(element);
            }else{
              if(element.city_classification == this.selected_city_classification){
                city_wise_spocs.push(element);
              }
            }
          });
          console.log("spoc_names2", city_wise_spocs);
          this.selected_spocs = city_wise_spocs;
          this.level_value = this.selected_level;
          this.updateValues();
        }
      },
      getSpocIds(spoc_data) {
        let spoc_list = spoc_data;
        for (let i = 0; i < spoc_list.length; i++) {
          const element = spoc_list[i];
          this.selected_spocs.push(element.id);
        }
        this.selected_spocs = this.selected_spocs.join();
      },
      getCityClasificationCodes(city) {
        let url = `${constants.SERVER_API}getCityClasificationCodes?city=${city}`;
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log("getCityClasificationCodes", response);
            
          
            this.city_classification_list = this.sortArrayAlphabetically(
              response.data
            );
            if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
             this.city_classification_list;
            }
            else{
              this.city_classification_list = this.city_classification_list.filter(item => item !== 'X');
           }
           
            if (localStorage.getItem("role") === "cm4") {

              this.selected_city_classification = this.city_classification_list[0];
            } else if (
              localStorage.getItem("role") === "cm1" ||
              localStorage.getItem("role") === "ac1" ||
              localStorage.getItem("role") === "exed"
            ) 
            
            
            {
              console.log("assigning the city classification check", this.loggedin);
              this.spoc_names.forEach((spoc) => {
                if (spoc.id === this.loggedin) {
                  console.log("assigning the city classification", spoc.city_classification);
                  this.selected_city_classification = spoc.city_classification;
                }
              }
               
              
              )
              
            }
              
            console.log(
              this.city_classification_list
              ,
              "city_classification_list"
            );
            
          })
          .catch((error) => {
            this.handleError(error);
          });
      },
      getrole(providedrole) {
        if (localStorage.getItem("role") === providedrole) {
          return true;
        } else {
          return false;
        }
      },
      getSpocList(selected_spocs) {
        console.log("selected spocs list step 1", selected_spocs);
        let selected_spocs_list = [];
        let team = localStorage.getItem('team')
        if (team == 'CM') {
          selected_spocs_list.push(localStorage.getItem('spoc_id'));
          // selected_spocs_list = selected_spocs_list.join();
          return selected_spocs_list;
        } else {
          selected_spocs.forEach((spoc) => {
            if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
              selected_spocs_list.push(spoc.id);
            }else {
              if(spoc.city_classification != 'X'){
                selected_spocs_list.push(spoc.id);
              }
            }
          });
          // selected_spocs_list = selected_spocs_list.join();
          return selected_spocs_list;
        }
      },
      async updateValues() {
        this.$vs.loading();
        let spocsCollection = [];
        let spoc_ids = this.getSpocList(this.selected_spocs);
        console.log("spocss", this.getSpocList(this.selected_spocs));
        for (let i = 0; i < spoc_ids.length; i++) {
            if (spoc_ids[i] != "") {
                let params = {
                  selected_date: this.datatoTimestamp(this.dateRange),
                  spoc_ids: spoc_ids[i],
                };
                let response = await this.getGmQueueReportData(params);
                spocsCollection.push(response.data[0]);
                console.log(response);
            }
        }
        this.generateDataTable(spocsCollection);
        this.$vs.loading.close();
      },
      getGmQueueReportData(params){
        let url = `${constants.SERVER_API}getGMQueueReportData`;
        const promise = new Promise((resolve, reject) => {
          axios
            .get(url, {
              params: params,
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              console.log(error);
              this.handleError(error);
              reject();
            });
        });
        return promise;
      },
      generateDataTable(response) {
          EventBus.$emit("get-ulevel-data", response, this.selected_city_classification, this.selected_city, this.spoc_names);
      },
      datatoTimestamp(stringyDate) {
        var dateyDate = new Date(this.timetoTimestamp(stringyDate));
        var ms = dateyDate.valueOf();
        return ms;
      },
      timetoTimestamp(incoming) {
        return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
      },
      getSpocs() {
        this.$vs.loading()
        // let url = `${constants.SERVER_API}getSpocs`;
        let url = ``;
        if (
          localStorage.getItem("role") === "cm1" ||
          localStorage.getItem("role") === "ac1" ||
          localStorage.getItem("role") === "cm4"
        ) {
          url = `${constants.SERVER_API}getSpocs`;
        } else if (localStorage.getItem("role") === "exed") {
          url = `${constants.SERVER_API}getExed`;
        }
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            this.loggedin = response.data.logged_in_user_id;
            this.spoc_names = response.data.spocs;
            // Uncommented this
            this.extractSpocByCity(response.data.spocs);
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.handleError(error);
            this.$vs.loading.close()
          });
      },
      extractSpocByCity(response) {
        var unique = response
          .map((name) => {
            return {
              count: 1,
              name: name.city,
            };
          })
          .reduce((a, b) => {
            a[b.name] = (a[b.name] || 0) + b.count;
            return a;
          }, {});
        this.cities = [];
        for (var key in unique) {
          if (unique.hasOwnProperty(key)) {
            this.cities.push(key);
          }
        }
        if(localStorage.getItem('team') == 'GM' && this.logged_in_team != 'Admin'){
          this.city_options = []
          this.city_options = this.cities;
          this.selected_city = this.city_options[0];
          let arr = [];
          response.forEach(element => {
            if(this.selected_city == element.city){
              arr.push(element)
            }
          });
          var unique1 = arr
            .map((name) => {
              return {
                count: 1,
                name: name.city_classification,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          this.city_classification_list = [];
          for (var key1 in unique1) {
            if (unique1.hasOwnProperty(key1)) {
              this.city_classification_list.push(key1);
            }
          }
          if(this.cities.length == 1){
            this.selected_city = this.cities[0];
            this.selected_city_classification = this.city_classification_list[0];
          }
        }
        // else{

        // if(localStorage.getItem('team') != 'GM' && localStorage.getItem("role") === "cm4"){
        //   this.cities = ["Pan India"].concat(this.sortArrayAlphabetically(this.cities));
        // }
        // this.cities.push("Pan India");
        // this.city_classification_list = this.sortArrayAlphabetically(this.city_classification_list);
        if ((localStorage.getItem("role") === "cm4" && localStorage.getItem("team") != "GM") || this.logged_in_team == 'Admin') {
          this.selected_city = this.city_options[0];
        }
        // else if (localStorage.getItem("team") == "GM") {
        //   this.city_options = []
        //   this.city_options = this.cities;
        //   this.selected_city = this.city_options[0];
        // }
        else if (
          localStorage.getItem("role") === "cm1" ||
          localStorage.getItem("role") === "ac1" ||
          localStorage.getItem("role") === "exed"
        ) {
          this.spoc_names.forEach((spoc) => {
            if (spoc.id === this.loggedin) {
              this.selected_city = spoc.city;
            }
          });
        }
      // }
        // this.getCityClasificationCodes(this.selected_city);
      },
    },
    components: {
    DateRangePicker,
    Datepicker,
    "v-select": vSelect,
    GmQueueReportTable
    }
  };
  </script>
  <style>
  .vs--searchable .vs__dropdown-toggle {
    height: 38px;
  }

  .fixed_columns_section {
    left: 0px;
    position: sticky;
    z-index: 100;
    background: white;
  }

  .fixed_header_level_section {
    left: 60px;
    position: sticky;
    z-index: 100;
    width: 100px;
    /* background: white; */
    /* border: 1px solid black; */
  }
  .fixed_header_level1_section{
    left: 80px;
    position: sticky;
    z-index: 100;
    width: 100px;
  }
  .fixed_first_row_section {
    position: sticky;
    top: 50px;
    background-color: white;
    z-index: 100000;
  }
  </style>
