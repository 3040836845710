<template>
    <div>
      <vs-table v-if="table_data.length != 0" :data="tableRows" style="margin-top: 3%; border-top: 1px solid; border-left: 1px solid; color: black" max-height="800px">
        <template slot="thead">
          <vs-th :key="index" v-for="(head, index) in tableHeaders" :colspan="head.colspan" style="border-right: 1px solid; position: sticky;
                                    top: 0px; background-color: white;"
            :class="{ fixed_columns_section: head.header == 'OverAll', fixed_header_level_section: head.header == 'Data' }">
            <span class="dataclass">{{
              head.header }}</span></vs-th>
        </template>
        <template slot-scope=" { data }">
          <vs-tr :key="indextr" class="rows" v-for="(tr, indextr) in data" :style="tr.style">
            <vs-td class="fixed_columns_section" :style="tr.style">{{ tr.overall }}</vs-td>
            <vs-td class="fixed_header_level_section1" :style="tr.style">{{ giveShortForm(tr.level) }}</vs-td>
            <!-- <vs-td :style="[selected_city === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
              <div @click="
                countDeails(
                  tr.level === '' ? tr.overall : tr.level,
                  selected_city,
                  'allocated',
                  selected_city_classification,

                )
              ">
                {{ tr.city.allocated }}
              </div>
            </vs-td> -->
            <!-- <vs-td :style="[selected_city === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
              <div @click="
                countDeails(
                  tr.level === '' ? tr.overall : tr.level,
                  selected_city,
                  'delays',
                  selected_city_classification,
                )
              ">
                {{ tr.city.delays }}
              </div>
            </vs-td> -->
            <!-- border-left-style: none; -->
            <vs-td v-for="(spoc, spocIndex) in tr.dynamicSpoc" :key="spocIndex" :colspan="tr.colspan"  :style="[emptyRows(tr.level) ? { 'border': '1px solid' } : { 'border': '1px solid', 'border-left-style': 'none' }]">
              <div class="count" @click="
                countDeails(
                  tr.level,
                  [tr, spocIndex],
                  data[0].dynamicSpoc[spocIndex],
                  selected_city_classification,

                )
              ">
                {{ spoc }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div v-else class="not-found">
        <b>No Data Found</b>
      </div>

      <vs-popup fullscreen id="coverageDetailspopup" :title="popupName" :active.sync="open">
        <vs-table :data="popupData">
          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Can ID</vs-th>
            <vs-th>M Level</vs-th>
            <vs-th>U Level</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody ref="tbody">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div v-if="tr.person_name != null">{{ tr.person_name }}</div>
                  <div v-else>Unkown</div>
                </vs-td>
                <vs-td>
                  <div>{{ tr.identity }}</div>
                </vs-td>
                <vs-td>
                  <div>{{ tr.mlevel }}</div>
                </vs-td>
                <vs-td>
                  <div>{{ tr.ulevel }}</div>
                </vs-td>
                <vs-td>
                  <v-row>
                    <vs-col v-if="tr.mwb_id != null" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <div @click="openPopupEmit(tr)">
                        <vs-icon
                          icon="info_outline"
                          size="small"
                          color="dark"
                        ></vs-icon>
                    </div>
                  </vs-col>

                  <vs-col v-if="tr.mwb_id == null" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1" style="margin-left: -3%;">
                  <div @click="calluntracked(tr)">
                      <vs-icon
                        icon="call"
                        size="small"
                        color="dark"
                      ></vs-icon>
                  </div>
                  </vs-col>
                  <vs-col v-if="tr.mwb_id == null" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                  <div @click="openNePopupEmit(tr)">
                      <vs-icon
                        icon="edit"
                        size="small"
                        color="dark"
                      ></vs-icon>
                  </div>
                </vs-col>
                </v-row>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>

        <vs-row style="margin-top: 3%">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
            <vs-chip color="primary">
              <b>{{ totalCount }}</b>
            </vs-chip>
            <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
          </vs-col>
        </vs-row>
      </vs-popup>
    </div>
</template>

  <script>
  import Datepicker from "vuejs-datepicker";
  import vSelect from "vue-select";
  import axios from "axios";
  import constants from "../../constants.json";
  import EventBus from "../components/eventbus";
  import DelaysMAccPopup from "./DelaysMAccPopup.vue";
  export default {
    mounted() {
      EventBus.$on("get-ulevel-data", (response, cc, city, spoc_names) => {
        this.table_data = response;
        this.selected_city_classification = cc;
        this.selected_city = city;
        this.spoc_names = spoc_names;
        this.generateDataTable(response);
      });
    // this.generateDataTable(this.spoc_data);

    },
    components: {
      Datepicker,
      "v-select": vSelect,
      DelaysMAccPopup,
    },
    watch: {
      dateRange() {
        this.getMAccLevelsCount();
      },
      currentTablePage: function() {
        this.paginationRequests();
      },
    },
    data() {
      return {
        table_data: [],
        dateRange: new Date(),
        MwbLeads: [],
        totalCount: 0,
        open: false,
        level: "",
        placement_data: "",
        type_data: "",
        selected_cc: "",
        title: "",
        currentTablePage: 1,
        tablelinks: 1,
        spoc_names: [],
        selected_city_classification: "",
        selected_city: "",
        macc_id: [],
        tableRows: [],
        tableHeaders: [],
        selected: [],
        tableList: [
          "vs-th: Component",
          "vs-tr: Component",
          "vs-td: Component",
          "thread: Slot",
          "tbody: Slot",
          "header: Slot",
        ],
        IIMLLevelsTooltip:
          // "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout, CMYTC - CM-Yet-To-Connect",
          "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout",
        selectall: false,
        expand: false,
        selectedleads: [],
        notselectedleads: [],
        tablerowULevelConstant: [
          {
            style: { background: "#d9d2e9", "font-weight": "bold" },
            level: "USP :",
          },
          {
            style: { background: "#d9d2e9" },
            level: "usp_enrollments_yesterday",
          },
          {
            style: { background: "#d9d2e9" },
            level: "usp_enrollments_mtd",
          },
          {
            style: { background: "#d9ead3", "font-weight": "bold" },
            level: "NON USP :",
          },
          {
            style: { background: "#d9ead3" },
            level: "not_usp_enrollments_yesterday",
          },
          {
            style: { background: "#d9ead3" },
            level: "not_usp_enrollments_mtd",
          },
          {
            style: { background: "#cfe2f3", "font-weight": "bold" },
            level: "Visits :",
          },
          {
            style: { background: "#cfe2f3" },
            level: "scheduled_visits_yesterday",
          },
          {
            style: { background: "#cfe2f3" },
            level: "completed_visits",
          },
          {
            style: { background: "#ead1dc", "font-weight": "bold" },
            level: "G-Meet :",
          },
          {
            style: { background: "#ead1dc" },
            level: "gmeet_enrollments_yesterday",
          },
          {
            style: { background: "#ead1dc" },
            level: "gmeet_enrollments_mtd",
          },
          {
            style: { background: "#fff2cc", "font-weight": "bold" },
            level: "Data :",
          },
          {
            style: { background: "#fff2cc" },
            level: "t_d_m6_u6",
          },
          {
            style: { background: "#fff2cc" },
            level: "ne_visits",
          },
          {
            style: { background: "#fff2cc" },
            level: "lead_visits",
          },
          {
            style: { background: "#fff2cc" },
            level: "t_d_u5_m5",
          },
          {
            style: { background: "#fff2cc" },
            level: "t_d_u3plusplus_m3plusplus",
          },
          {
            style: { background: "#fff2cc" },
            level: "ne_w_g_l_s",
          },
          // {
          //   style: { background: "#fff2cc" },
          //   level: "mhp_m6_m5_m3plusplus_m3plus_m3",
          // },
          {
            style: { background: "#fff2cc" },
            level: "mhp_bucket1",
          },
          {
            style: { background: "#fff2cc" },
            level: "ne_others",
          },
          {
            style: { background: "#fff2cc" },
            level: "mhp_bucket2",
          },
          {
            style: { background: "#fff2cc" },
            level: "t_d_u3plus",
          },
          {
            style: { background: "#fff2cc" },
            level: "missed",
          },
          {
            style: { background: "#fff2cc" },
            level: "untracked",
          },
          // {
          //   style: { background: "#fff2cc" },
          //   level: "sub_total_1",
          // },
          {
            style: { background: "#fff2cc" },
            level: "t_d_u3",
          },
          {
            style: { background: "#fff2cc" },
            level: "t_d_m3plus",
          },
          {
            style: { background: "#fff2cc" },
            level: "t_d_m3",
          },
          // {
          //   style: { background: "#fff2cc" },
          //   level: "total_cumulative",
          // },
        ],
        popupName : '',
        popupData: [],
        selected_obj: '',
      };
    },
    methods: {
      generateDataTable(response) {
        this.tableRows = this.tablerowULevelConstant;
        this.generateTableHeaders(response);
        this.generateTableRows(response);
      },
      generateTableHeaders(response) {
        this.tableHeaders = [];
        // this.tableHeaders.push({
        //   header: "",
        //   colspan: 1,
        //   fixed: true,
        // });
        this.tableHeaders.push({
          header: "Data",
          colspan: 2,
          fixed: true,
        });
        if(this.selected_city != 'Pan India'){
        // let city_code = this.selected_city_classification;
        // let city = this.selected_city.concat(" ", "(", city_code, ")");
        // this.tableHeaders.push({
        //   header: city,
        //   colspan: 2,
        // });
        response.forEach((cityData) => {
          let obj = {
            header: cityData.user_name,
            colspan: 2,
          };
          this.tableHeaders.push(obj);
        });
      }else {
          this.tableHeaders.push({
            header: '',
            colspan: 2,
          });
          response.forEach((cityData) => {
          let obj = {
            header: cityData.city,
            colspan: 2,
          };
          this.tableHeaders.push(obj);
        });
        }
      },
      generateTableRows(response) {
        this.tableRows[0].dynamicSpoc = [];
        if(this.selected_city != 'Pan India'){
        this.tableRows[0].city = {
          allocated: "Allocated",
          delays: "Delays"
        };
        }else{
          this.tableRows[0].city = {}
        }
        this.tableRows.forEach((row, index) => {
          if (index !== 0) {
            row.dynamicSpoc = [];
            if(this.selected_city != 'Pan India'){
            row.city = {
              allocated: 0,
              delays: 0
            };
          }else{
            row.city = {};
          }
        }
        });
        response.forEach((data) => {

        let desc = data.description;
        for (var key in desc) {
          if (desc.hasOwnProperty(key)) {
            // console.log(key + " -> " + desc[key]);
            this.tableRows.forEach((row) => {
                if (row.level == key) {
                row.dynamicSpoc.push(desc[key]);
                row.colspan = 2;
                }
                // else{
                //   row.dynamicSpoc.push(1);
                //   row.colspan = 2;
                // }
            });
           }
        }
        });
        this.tableRows.forEach((row) => {
          if (row.level == "USP :" || row.level == "NON USP :" || row.level == "Visits :" || row.level == "G-Meet :" || row.level == "Data :") {
            row.dynamicSpoc.push('');
            let cols = 0;
            this.tableHeaders.forEach(ele => {
              cols = ele.colspan + cols
            });
            row.colspan = cols;
          }
        });
      },

      mapSearchSpoc(placement) {
      console.log("placement1", placement);
        let obj = placement[0];
        let objindex = placement[1];
        let objlength = obj.dynamicSpoc.length;
        let mapObj = [];
        for (let i = 1; i <= objlength; i++) {
          mapObj.push({
            sequence: i,
            data: []
          });
        }
        let count = 0;
        let map = 0;
        let mapname = 0;
        obj.dynamicSpoc.forEach((spocstat, index) => {
          if (count === 1) {
            count = 0;
            map = map + 1;
          }
          mapObj[map].data.push(spocstat);
          if (index === objindex) {
            mapname = map;
          }
          count = count + 1;
        });
        let spoc = this.foundspoc(mapname);
        return spoc;
      },
      foundspoc(spocindex) {
        let spocarr = [];
        this.tableHeaders.forEach((header, index) => {
          if (index > 0) {
            spocarr.push(header);
          }
        });
        return spocarr[spocindex].header;
      },
      convertType(type) {
        let newtype = "";
        newtype = type.toLowerCase();
        return newtype;
      },
      countDeails(level, placement, type, selected_city_classification) {
        console.log("clicked data", level, placement, type, selected_city_classification);
        let city_classification = selected_city_classification;
        // if (type === "Allocated" || type === "Delays") {
        //   let newtype = this.convertType(type);
          let spocname = this.mapSearchSpoc(placement);
          console.log("spocnamee", spocname);
          // this.level = level,
          // this.type_data = newtype;
          // this.selected_cc = city_classification
          this.getDetailsData(level, spocname, city_classification);
        // } else {
          // this.level = level,
          // this.type_data = type;
          // this.selected_cc = city_classification
        //   this.getDetailsData(level, placement, type, city_classification);
        // }
      },
      getDetailsData(level, placement, city_classification) {
        this.open = true;
        console.log("spoc idd",level, placement, city_classification);
        // this.title = `${level} - ${placement} - ${type}`;
        let dec_name = this.giveShortForm(level);
        this.popupName = `${placement.toUpperCase()} - ${dec_name.toUpperCase()}`;
        this.popupData = []
        this.spoc_names.forEach((spoc) => {
          if (spoc.full_name === placement) {
            placement = spoc.id;
            this.placement_data = placement;
          }
        });
        if (this.dateRange === "") {
          this.$vs.notify({
            title: "Select a Date",
            text: "Select a date to continue using this feature",
            color: "warning",
          });
        }
        else {
          this.$vs.loading();
          let url = `${constants.SERVER_API}getGMQueueReportDetails`;
          let obj = {
            // selected_date: this.datatoTimestamp(this.dateRange),
            type: level,
            // type: type,
            spoc_id: "",
            // city: "",
            // city_classification: "",
            // selected_level_type: 'u_level',
          };

          if (Number.isInteger(placement)) {
            obj.spoc_id = placement;
          } else {
            obj.city = placement;
            obj.city_classification = city_classification;
          }
          if (obj.level === "Total") {
            obj.level = "U";
          }
          this.selected_obj = obj;
          console.log("finsskjkdjs", obj)
          axios
            .get(url, {params: obj,
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            })
            .then((response) => {
              console.log("getVisitSchedulesContactDetails", response.data);
              // response.data.data.forEach((lead) => {
              //   lead.engagements = [];
              //   lead.mobile = [];
              //   lead.email = [];
              // });
              // this.$store.commit("MOUNT_MWB_LEADS", response.data.data);

              // let popupcount = response.data.total;
              // console.log("counnt", popupcount);
              // EventBus.$emit("open-coverage-details", [
              //   obj,
              //   url,
              //   response.data,
              //   "getVisitSchedulesContactDetails",
              //   popupName + "-" + popupcount,
              // ]);
              this.popupData = response.data.data;
              this.tablelinks = response.data.last_page;
              this.totalCount = response.data.total;
              this.popupName = `${this.popupName} - ${this.totalCount}`;
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.handleError(error);
              this.$vs.loading.close();
            });
        }
      },
      emptyRows(val){
        if(val == "USP :" || val == "NON USP :" || val == "Visits :" || val == "G-Meet :" || val == "Data :"){
          return false;
        }else{
          return true;
        }
      },
      giveShortForm(given_type) {
      switch (given_type) {
        case "usp_enrollments_yesterday":
          return "Enrollments Yesterday";
        case "usp_enrollments_mtd":
          return "Enrollments MTD";
        case "not_usp_enrollments_yesterday":
          return "Enrollments Yesterday";
        case "not_usp_enrollments_mtd":
          return "Enrollments MTD";
        case "scheduled_visits_yesterday":
          return "How many Visits Scheduled Yesterday";
        case "completed_visits":
          return "How many visits completed";
        case "gmeet_enrollments_yesterday":
          return "How many G-Meets Yesterday";
        case "gmeet_enrollments_mtd":
          return "How many G-Meets MTD";
        case "t_d_m6_u6":
          return "Today & Delays of M6 & U6";
        case "ne_visits":
          return "NE Visits Today";
        case "lead_visits":
          return "Lead Visits Today";
        case "t_d_u5_m5":
          return "Today & Delays of U5 & M5";
        case "t_d_u3plusplus_m3plusplus":
          return "Today & Delays of U3++ & M3++";
        case "ne_w_g_l_s":
          return "NEs : Bucket 1";
        // case "mhp_m6_m5_m3plusplus_m3plus_m3":
        //   return "MHP(Reinquiries) M6,M5,M3++,M3+,M3";
        case "mhp_bucket1":
          return "MHPs : Bucket 1";
          case "mhp_bucket2":
          return "MHPs : Bucket 2";
        case "ne_others":
          return "NEs : Bucket 2";
        case "t_d_u3plus":
          return "Today & Delays of U3+";
        case "missed":
          return "Missed Calls";
        case "untracked":
          return "Untracked Calls";
        // case "sub_total_1":
        //   return "Sub Total";
        case "t_d_u3":
          return "Today & Delays of U3";
        case "t_d_m3plus":
          return "Today & Delays of M3+";
        case "t_d_m3":
          return "Today & Delay (M3)";
        // case "total_cumulative":
        //   return "Total Cumulative";
        default:
          return given_type;
      }
      },
      openPopupEmit(payload) {
        this.openBigPopup(payload.mwb_id);
      },
      paginationRequests() {
        this.$vs.loading();
        let url = `${constants.SERVER_API}getGMQueueReportDetails`;
        this.selected_obj.page = this.currentTablePage;
        axios
          .get(url, {params: this.selected_obj,
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            this.popupData = response.data.data;
            this.tablelinks = response.data.last_page;
            this.totalCount = response.data.total;
            this.$vs.loading.close();
          })
          .catch(error => {
            this.handleError(error);
          });
      },

      openNePopupEmit(ne_data) {
            console.log(ne_data);
            let obj = ne_data
            let { person_name: name, phone: mobile, ...rest } = obj;
            obj = { name, mobile, ...rest }
            obj["course"] = "MAcc";
            obj["net_enquiry_type"] = "Webinar-WR";
            EventBus.$emit("open-netEnquiries-details-popup", obj);
      },
      async calluntracked(data) {
            console.log(data);
            let obj = data
            let { person_name: name, phone: mobile, ...rest } = obj;
            obj = { name, mobile, ...rest }
            obj["course"] = "MAcc";
            obj["net_enquiry_type"] = "Webinar-WR";
            let response = await this.checkPermission();
            if (localStorage.getItem("role") !== "cm4") {
                if (
                    this.$route.path !== "/queue" &&
                    this.$route.path !== "/placementDrive" &&
                    this.$route.path !== "/logout" &&
                    this.$route.path !== "/UtilizationReport" &&
                    this.$route.path !== "/receipts"
                ) {
                    if (response === "no") {
                        this.$vs.notify({
                            title: "Permission denied",
                            text: "Please contact admin!!!",
                            color: "danger",
                            icon: "error",
                        });
                        return;
                    }
                }
            }
            let params = {};
            params.phone_number = data.phone;
            params.email = data.email;
            axios
                .get(constants.SERVER_API + "clickToCallUntracked", {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                        EventBus.$emit("open-netEnquiries-details-popup", obj);
                })
                .catch((error) => {
                    this.handleError(error);
                });
      },
    },
  };
  </script>

  <style scoped>
    .vs--searchable .vs__dropdown-toggle {
    height: 38px;
  }

  .fixed_columns_section {
    left: 0px;
    position: sticky;
    z-index: 100;
    background: white;
    border: 1px solid;
    border-left-style: none;
    border-right-style: none;
  }

  .fixed_header_level_section {
    left: 0px;
    position: sticky;
    z-index: 100;
    /* width: 100px; */
    background: white;
    text-align: center;
  }
  .fixed_header_level_section1 {
    left: 0px;
    position: sticky;
    z-index: 100;
    width: 15%;
    background: white;
    border: 1px solid;
    border-left-style: none;
    border-right-style: none;
  }
  .fixed_first_row_section {
    position: sticky;
    top: 50px;
    background-color: white;
    z-index: 1000000;
  }
  .not-found{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    text-align: center;
    margin:30px 0px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #bebcbc;
  }
  @media only screen and (max-width: 1400px) {
      .not-found{
      font-size: 11px;
  }
  }
.rows{
  border: 1px solid;
}
.dataclass {
  margin-left: 10%;
}
.count{
  display: flex;
  justify-content: center;
  align-items: center;
}
  </style>
