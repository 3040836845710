var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.table_data.length != 0)?_c('vs-table',{staticStyle:{"margin-top":"3%","border-top":"1px solid","border-left":"1px solid","color":"black"},attrs:{"data":_vm.tableRows,"max-height":"800px"},scopedSlots:_vm._u([{key:"default",fn:function( ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,staticClass:"rows",style:(tr.style)},[_c('vs-td',{staticClass:"fixed_columns_section",style:(tr.style)},[_vm._v(_vm._s(tr.overall))]),_c('vs-td',{staticClass:"fixed_header_level_section1",style:(tr.style)},[_vm._v(_vm._s(_vm.giveShortForm(tr.level)))]),_vm._l((tr.dynamicSpoc),function(spoc,spocIndex){return _c('vs-td',{key:spocIndex,style:([_vm.emptyRows(tr.level) ? { 'border': '1px solid' } : { 'border': '1px solid', 'border-left-style': 'none' }]),attrs:{"colspan":tr.colspan}},[_c('div',{staticClass:"count",on:{"click":function($event){return _vm.countDeails(
              tr.level,
              [tr, spocIndex],
              data[0].dynamicSpoc[spocIndex],
              _vm.selected_city_classification

            )}}},[_vm._v("\n            "+_vm._s(spoc)+"\n          ")])])})],2)})}}],null,false,2186564193)},[_c('template',{slot:"thead"},_vm._l((_vm.tableHeaders),function(head,index){return _c('vs-th',{key:index,class:{ fixed_columns_section: head.header == 'OverAll', fixed_header_level_section: head.header == 'Data' },staticStyle:{"border-right":"1px solid","position":"sticky","top":"0px","background-color":"white"},attrs:{"colspan":head.colspan}},[_c('span',{staticClass:"dataclass"},[_vm._v(_vm._s(head.header))])])}),1)],2):_c('div',{staticClass:"not-found"},[_c('b',[_vm._v("No Data Found")])]),_c('vs-popup',{attrs:{"fullscreen":"","id":"coverageDetailspopup","title":_vm.popupName,"active":_vm.open},on:{"update:active":function($event){_vm.open=$event}}},[_c('vs-table',{attrs:{"data":_vm.popupData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
return [_c('tbody',{ref:"tbody"},_vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',[(tr.person_name != null)?_c('div',[_vm._v(_vm._s(tr.person_name))]):_c('div',[_vm._v("Unkown")])]),_c('vs-td',[_c('div',[_vm._v(_vm._s(tr.identity))])]),_c('vs-td',[_c('div',[_vm._v(_vm._s(tr.mlevel))])]),_c('vs-td',[_c('div',[_vm._v(_vm._s(tr.ulevel))])]),_c('vs-td',[_c('v-row',[(tr.mwb_id != null)?_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('div',{on:{"click":function($event){return _vm.openPopupEmit(tr)}}},[_c('vs-icon',{attrs:{"icon":"info_outline","size":"small","color":"dark"}})],1)]):_vm._e(),(tr.mwb_id == null)?_c('vs-col',{staticStyle:{"margin-left":"-3%"},attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"1"}},[_c('div',{on:{"click":function($event){return _vm.calluntracked(tr)}}},[_c('vs-icon',{attrs:{"icon":"call","size":"small","color":"dark"}})],1)]):_vm._e(),(tr.mwb_id == null)?_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"2"}},[_c('div',{on:{"click":function($event){return _vm.openNePopupEmit(tr)}}},[_c('vs-icon',{attrs:{"icon":"edit","size":"small","color":"dark"}})],1)]):_vm._e()],1)],1)],1)}),1)]}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Name")]),_c('vs-th',[_vm._v("Can ID")]),_c('vs-th',[_vm._v("M Level")]),_c('vs-th',[_vm._v("U Level")]),_c('vs-th',[_vm._v("Action")])],1)],2),_c('vs-row',{staticStyle:{"margin-top":"3%"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"flex-end","vs-w":"12"}},[_c('vs-chip',{attrs:{"color":"primary"}},[_c('b',[_vm._v(_vm._s(_vm.totalCount))])]),_c('vs-pagination',{attrs:{"total":_vm.tablelinks},model:{value:(_vm.currentTablePage),callback:function ($$v) {_vm.currentTablePage=$$v},expression:"currentTablePage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }